import { GetPasswordPolicy, PasswordPolicy } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getPasswordPolicy = async (subdomain: string) => {
  const { data } = await axiosClient.get<GetPasswordPolicy>(
    `${fullUrl()}/password_policy`,
    { params: { subdomain: subdomain } }, // paramsの渡し方を修正したけど、そもそもapi側ではurlの方のsubdomainをみてるのでparamsにはいらない
  )
  return data
}
