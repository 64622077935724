import React from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { Loading } from 'presentation/components/Loading'
import { StringParam, useQueryParam } from 'use-query-params'
import { Main } from './Main'

export const PasswordReset = () => {
  const [token] = useQueryParam('token', StringParam)

  if (typeof token === 'undefined' || token === null || token === '') {
    return (
      <Layout>
        <main className="pt-10 pb-20 w-full flex justify-center">
          <Loading />
        </main>
      </Layout>
    )
  }

  return (
    <Layout dataTest="loadedLayout">
      <Main
        token={token}
      />
    </Layout>
  )
}
