import { fullUrl } from 'scripts/fullUrl'
import { PostPasswordResetConfirmResponse } from 'types/ApiResponse'
import { PostPasswordResetConfirmRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postPasswordResetConfirm = async (
  params: PostPasswordResetConfirmRequest,
) => {
  const res = await axiosClient.post<PostPasswordResetConfirmResponse>(
    `${fullUrl()}/password_reset/confirm`,
    params,
  )
  return res
}
