import React, { FC, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { postPasswordResetConfirm } from 'api/postPasswordResetConfirm'
import { getPasswordPolicy } from 'api/getPasswordPolicy'
import { useTranslation } from 'react-i18next'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { PasswordPolicy } from 'types/ApiResponse'

interface Props {
  token: string
}

export const Main: FC<Props> = ({ token }) => {
  const [pass, setPass] = useState('')
  const [passConfirm, setPassConfirm] = useState('')
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>()
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      // HACK: useEffectはwindow見つからないことはないはず。refactor
      const subdomain = getSubdomain(window.location.host)
      const { password_policy } = await getPasswordPolicy(subdomain)
      setPasswordPolicy(password_policy)
    })()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'pass':
        setPass(event.target.value)
        break
      case 'passConfirm':
        setPassConfirm(event.target.value)
        break
      default:
        break
    }
  }

  const handleClick = async () => {
    if (!pass) {
      openSnackbar('パスワードを入力してください', 'blankInput')
      return
    }
    if (pass !== passConfirm) {
      openSnackbar('パスワードと確認用が一致しません', 'notMatchInput')
      return
    }
    const params = {
      password_reset: {
        token,
        password: pass,
        password_confirm: passConfirm,
      },
    }
    const { status, data } = await postPasswordResetConfirm(params)
    if (status === 200) {
      openSnackbar(
        'パスワードをリセットしました。ログイン画面に戻ります。',
        'passwordResetedSnackbar',
      )
      setTimeout(() => {
        navigate('/login')
      }, 3000)
      return
    } else if (data.reason === 'INVALID_PASSWORD') {
      openSnackbar(
        'パスワードが条件を満たしていません',
        'failureToMeetPassworldPolicy',
      )
    } else {
      openSnackbar('パスワード再設定に失敗しました', 'wrongTokenSnackbar')
    }
  }
  return (
    <>
      <p className="text-xl">{t('パスワードを再設定する')}</p>
      {typeof passwordPolicy !== 'undefined' ? (
        <div className="w-full px-6 pt-5">
          <div
            style={{
              fontSize: '0.75em',
              color: 'rgba(0, 0, 0, 0.54)',
              paddingBottom: '10px',
            }}
          >
            パスワードは次の条件を満たす必要があります。
            <br />
            <br />
            <>
              ・最小文字数： {passwordPolicy.minimum_length}文字 <br />
            </>
            <>
              ・最大文字数： {passwordPolicy.maximum_length}文字 <br />
            </>
            {passwordPolicy.lowercase_required ? (
              <>
                ・少なくとも小文字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.uppercase_required ? (
              <>
                ・少なくとも大文字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.number_required ? (
              <>
                ・少なくとも数字を1文字使う
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.symbol_required ? (
              <>
                ・記号を1文字以上使う（! @ # $ % ^ & * _）
                <br />
              </>
            ) : (
              <></>
            )}
            {passwordPolicy.not_allow_last_password ? (
              <>
                ・直前のパスワードは再利用できません
                <br />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full px-6 pt-5">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('新しいパスワード')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="pass"
          type="password"
          name="pass"
          value={pass}
          onChange={handleChange}
        />
        <p className="pt-3 text-xs font-semibold text-gray-700 pb-1">
          {t('新しいパスワード（確認用）')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="passConfirm"
          type="password"
          name="passConfirm"
          value={passConfirm}
          onChange={handleChange}
        />
      </div>
      <div className="w-full px-6 my-8">
        <button
          className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
        >
          {t('再設定する')}
        </button>
      </div>
    </>
  )
}
